import Coingecko from '../../assets/img/svg/coingecko.svg'
import Coinmarketcap from '../../assets/img/svg/coinmarketcap.svg'
import Discord from '../../assets/img/svg/discord.svg'
import LinkedIn from '../../assets/img/svg/linkedIn.svg'
import Logo from '../../assets/img/svg/logo.svg'
import Medium from '../../assets/img/svg/medium.svg'
import Reddit from '../../assets/img/svg/reddit.svg'
import Telegram from '../../assets/img/svg/telegram.svg'
import Twitter from '../../assets/img/svg/twitter.svg'

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container ">
        <div className="footer__top">
          <a href="#" className="footer__logo logo">
            <img src={Logo} className="footer__logo-icon logo-icon logo-icon--white" />
            <p className="footer__logo-text logo-text logo-text--white">SAWA</p>
          </a>
          <p className="footer__text footer__text--top">
            SAWA is a decentralized multi-chain fundraising platform enabling projects to raise capital and promise
            safety to early stage investors. Stake Infinite Launch tokens to get priority-access to promising projects.
          </p>
        </div>
        <div className="footer__body">
          <nav className="footer__menu">
            <ul className="footer__menu-list">
              <li className="footer__menu-item">
                <a href="https://sawastarter.com/blog" className="footer__menu-link">
                  Blog
                </a>
                <span className="footer__menu-item-new">new</span>
              </li>
              <li className="footer__menu-item">
                <a href="https://sawastarter.com/faq" className="footer__menu-link">
                  FAQ
                </a>
              </li>
              <li className="footer__menu-item">
                <a href="https://sawastarter.com/" className="footer__menu-link">
                  RWA marketplace
                </a>
              </li>
              <li className="footer__menu-item">
                <a href="#" className="footer__menu-link">
                  Expert
                </a>
                <span className="footer__menu-item-new">new</span>
              </li>
              <li className="footer__menu-item">
                <a href="https://sawastarter.com/expert" className="footer__menu-link">
                  Apply (for projects)
                </a>
              </li>
              <li className="footer__menu-item">
                <a href="https://docsend.com/view/bp85bce52vch7gsw" className="footer__menu-link">
                  Pitch Deck
                </a>
              </li>
              <li className="footer__menu-item">
                <a href="https://docsend.com/view/ebp4v5tbcrnydnhn" className="footer__menu-link">
                  White paper
                </a>
              </li>
            </ul>
          </nav>
          <div className="footer__links">
            <ul className="footer__socials">
              <li className="footer__socials-item ">
                <a href="https://www.linkedin.com/company/sawa-crypto" className="footer__socials-link">
                  <img src={LinkedIn} className="footer__socials-icon" />
                </a>
              </li>
              <li className="footer__socials-item ">
                <a href="https://twitter.com/sawaprotocol" className="footer__socials-link">
                  <img src={Twitter} className="footer__socials-icon" />
                </a>
              </li>
              <li className="footer__socials-item ">
                <a href="https://discord.gg/MJEFRkgCBW" className="footer__socials-link">
                  <img src={Discord} className="footer__socials-icon" />
                </a>
              </li>
              <li className="footer__socials-item ">
                <a href="https://t.me/+qjDVeKUsLJtlZWIy" className="footer__socials-link">
                  <img src={Telegram} className="footer__socials-icon" />
                </a>
              </li>
              <li className="footer__socials-item ">
                <a href="https://medium.com/@SAWA_CRYPTO" className="footer__socials-link">
                  <img src={Medium} className="footer__socials-icon" />
                </a>
              </li>
              <li className="footer__socials-item ">
                <a href="https://www.reddit.com/r/SAWA_CRYPTO_official/" className="footer__socials-link">
                  <img src={Reddit} className="footer__socials-icon" />
                </a>
              </li>
            </ul>
            <div className="footer__cryptodata">
              <a href="https://coinmarketcap.com/currencies/sawa-crypto/" className="footer__cryptodata-link">
                <img className="footer__cryptodata-icon" src={Coinmarketcap} alt="coinmarketcap" />
              </a>
              <a href="https://www.coingecko.com/en/coins/sawa-crypto" className="footer__cryptodata-link">
                <img className="footer__cryptodata-icon" src={Coingecko} alt="coingecko" />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="footer__bottom">
          <p className="footer__text footer__text--bottom">SAWA BILISM TEKNOLOJI ltd.</p>
          <p className="footer__text footer__text--bottom">
            registered office is located at: Maslak Mah. Tasyoncasi Sk.Maslak 1453 Sitesi lad A4 block No: lad Interior
            Door No: 25, Sariyer/Istanbul registered at th Istanbul Trade Registry, registration number: 404029-5
          </p>
        </div> */}
      </div>
    </footer>
  )
}

export default Footer
